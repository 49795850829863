<template>
  <div class="tc-evaluate_report">
    <!-- 头部工具栏 -->
    <div class="tool" v-if="showBack">
      <ul>
        <li>
          <img src="@/assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 头部功能 -->
    <div class="header">
      <div class="header-operation">
        <ul>
          <li @click="print">
            <img src="../../../assets/images/user_information_print.png" alt />
          </li>
          <!-- <li>
            <img src="../../../assets/images/user_information_set.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_batch.png" alt />
          </li>-->
          <li @click="exportInfo">
            <img src="../../../assets/images/export.png" alt />
          </li>
          <!-- <li>
            <img src="../../../assets/images/user_information_query.png" alt />
          </li>-->
        </ul>
      </div>
      <div class="header-tool">
        <div class="del" @click="delDatas">
          <img src="../../../assets/images/del.png" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="报告类型" prop="classify_name">
            <el-select v-model="formInline.classify_name">
              <el-option
                :label="item.classify_name"
                :value="item.id"
                v-for="item in MeasureClass"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门" prop="department_id">
            <el-select v-model="formInline.department_id">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="real_name">
            <el-input v-model="formInline.real_name"></el-input>
          </el-form-item>
          <el-form-item label="用户编号" prop="account">
            <el-input v-model="formInline.account"></el-input>
          </el-form-item>
          <el-form-item label="量表名称" prop="measure_title">
            <el-input v-model="formInline.measure_title"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="queryUser">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
            <el-button @click="batch">批量审核</el-button>
            <!-- <el-button plain>预警</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="570"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_title"
          label="量表名称"
          align="left"
          width="400"
          header-align="center"
        ></el-table-column>
        <el-table-column prop="department_name" label="部门名称" align="center">
          <template slot-scope="scope">
            <!--  -->
            <span v-if="scope.row.id != issel" @click="showsel(scope.row)">{{
              scope.row.department_name
            }}</span>
            <!--  -->
            <el-select
              v-model="selvalue"
              :placeholder="scope.row.department_name"
              size="nimi"
              v-if="scope.row.id == issel"
              @change="hidesel(scope.row)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_name"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex | sexFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_account"
          label="用户编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="测评日期"
          align="center"
        ></el-table-column>
        <el-table-column prop="warning" label="预警" width="80" align="center">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.warning == 1
                  ? 'off'
                  : scope.row.warning == 2
                  ? 'risk'
                  : 'no'
              "
              >{{
                scope.row.warning == 1
                  ? "预警"
                  : scope.row.warning == 2
                  ? "异常"
                  : "正常"
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="inspect"
          label="审核状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.inspect == 0 ? 'off' : 'no'"
              @click="upInspect(scope.row)"
              style="cursor: pointer"
              v-if="scope.row.inspect == 0"
              >{{ scope.row.inspect === 1 ? " 通过审核" : " 未审核 " }}</span
            >
            <span
              :class="scope.row.inspect == 0 ? 'off' : 'no'"
              style="cursor: pointer"
              v-else
              >{{ scope.row.inspect === 1 ? " 通过审核" : " 未审核 " }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="is_print"
          label="打印状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.is_print == 1"
              >已打印</span
            >
            <span
            v-else
              >未打印</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="goReport(scope.row)"
              >查看</el-button
            >
            <!-- <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="edit(scope.row.id)">切换</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="pageSize"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { loadDepartmentData, getUserDepartment } from "@/api/comm.js";
import {
  getEvaluateReport,
  indexMeasure,
  inspectUp,
  delEvaluate,
  department_up,
  
} from "@/api/information.js";
import { getMeasureClassifyList ,auditAuthority} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      options: [],
      selvalue: "",
      issel: -1,
      // --------
      loading: true,
      multipleSelection: [],
      tableData: [],
      formInline: {
        classify_name: "",
        department: "",
        userName: "",
        scaleName: "",
        account: "",
        real_name: "",
        measure_title: "",
        department_id: "",
        warning: "",
      },
      uid: "",
      // 页码
      page: 1,
      pageSize: 15,
      total: 0,
      departmentList: [],
      showBack: false,
      MeasureClass: [],
      toexamineFlag:false,
    };
  },
  created() {
    // 获取部门信息
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取所有部门
    getUserDepartment({ admin: "admin" }).then((res) => {
      this.options = res.data;
    });
    // 获取分类
    getMeasureClassifyList({ page: 1, size: 9999 }).then((res) => {
      this.MeasureClass = res.data.data;
    });
    // 首页判断加载加载
    if (this.$route.query.id) {
      this.uid = this.$route.query.id;
      this.loadListData();
      this.showBack = true;
    } else {
      this.loadListData();
      this.showBack = false;
    }
    this.toExamine();
  },
  methods: {
    // 审核资格获取
    toExamine(){
        let adminName = localStorage.getItem("adminName");
        //  console.log("开始获取",adminName);
       auditAuthority({real_name:adminName}).then(res=>{
        //  console.log("开始获取",res.data.inspectStatus);
          if(res.data.inspectStatus==1){
            this.toexamineFlag=true;
          }
       })
    },

    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      //console.log(p);
    },
    // 页码
    handleCurrentChange(val) {
      this.loading = true;
      this.page = val;
      this.loadListData();
    },
    handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.loadListData();
      //console.log(`每页 ${val} 条`);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 加载列表
    loadListData() {
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        account: this.formInline.account,
        real_name: this.formInline.real_name,
        measure_title: this.formInline.measure_title,
        department_id: this.formInline.department_id,
        uid: this.uid,
        classify_name: this.formInline.classify_name,
      };
      indexMeasure(data).then((res) => {
        //console.log(res);
        this.tableData = res.data;
        this.total = res.count;
        this.loading = false;
      });
    },
    // 查看报告
    goReport(row) {
        if (row.inspect != 0 ) {
            let id = row.id;
            this.$router.push(`/evaluate_report/report?id=${id}`);
          // if(this.toexamineFlag){
          //   let id = row.id;
          //   this.$router.push(`/evaluate_report/report?id=${id}`);
          // }else {
          //   this.$message({
          //     showClose: true,
          //     duration: 1000,
          //     message: "您没有查看权限",
          //     type: "warning"
          //   });
          // }
        } else {
        this.$message({
          showClose: true,
          duration: 1000,
          message: "未审核报告不能查看",
          type: "warning"
        });
      }
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = this.multipleSelection.toString();
          delEvaluate({ id: data }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "批量删除成功",
                type: "success",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {});
    },
    //  查询
    queryUser() {
      this.page = 1;
      this.loadListData();
      this.$message({
        showClose: true,
        message: "查询成功",
        type: "success",
      });
    },
    // 打印报告
    print() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要打印的数据",
          type: "warning",
        });
        return;
      }
      this.$router.push(
        `/evaluate_report/report?ids=${this.multipleSelection}`
      );
    },
    //  导出用户数据
    exportInfo() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要导出数据",
          type: "warning",
        });
        return;
      }

      window.location.href = `/get_excel?ids=${this.multipleSelection}&download`;
    },
    // 审核
    upInspect(row) {
      if(!this.toexamineFlag){
        this.$message({
          showClose: true,
          duration: 1000,
          message: "您没有审核权限",
          type: "warning"
        });
        return
      }
      let id = row.id;
      this.$confirm("是否审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "审核成功!",
          });
          inspectUp({ id }).then((res) => {
            if (res.code == 400200) {
              row.inspect = 1;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // 显示部门切换选择框
    showsel(row) {
      this.issel = row.id;
    },
    hidesel(row) {
      this.issel = -1;
      row.department_name = this.selvalue;
      // 找到ID
      let id = "";
      this.options.forEach((v) => {
        if (v.name == this.selvalue) {
          id = v.id;
          return;
        }
      });
      let data = {
        department: id,
        id: row.id,
      };
      department_up(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    // 批量审核
    batch() {
      if(!this.toexamineFlag){
        this.$message({
          showClose: true,
          duration: 1000,
          message: "您没有审核权限",
          type: "warning"
        });
        return
      }
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要审核的数据",
          type: "warning",
        });
        return;
      }
      let id = this.multipleSelection.toString();
      this.$confirm("自动过滤掉已审核的数据，是否审核通过? ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          inspectUp({ id }).then((res) => {
            if (res.code == 400200) {
              this.$message({
                type: "success",
                message: "审核成功!",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "evaluate_reportInfo") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    //开启了keepAlive:true后再次进入，以前的搜索条件和页数都不会变，无论什么情况都调用一下获取数据的接口，这样就能得到当前搜索条件和页数的最新数据
    if (this.$route.meta.ifDoFresh) {
      //重置ifDoFresh
      this.$route.meta.ifDoFresh = false; //获取列表数据方法第一参数为是否重置搜索条件和页数
      // this.loadListData();
    } else {
      this.loadListData();
    }
  },
};
</script>

<style lang="less">
.tc-evaluate_report {
  width: 100%;
  min-width: 1350px;
  position: relative;
  .tool {
    position: absolute;
    top: -50px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;

          img {
            width: 100%;
            // pointer-events: none;
            cursor: pointer;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 150px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 570px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .risk {
      color: chocolate;
    }
    .el-input__inner {
      // width: 50px;
      // padding: 5px;
      // text-align: center;
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>